<template>
  <div class="all">
    <div v-if="flag === 1" class="top">
      <div v-for="child in timeLineList" :key="child.id" class="steps">
        <div class="quan" />
        <div class="neibu">
          <h3>{{ `【${child.deliveryCompany}】${ child.description }` }}</h3>
          <p>{{ child.createTime }}</p>
        </div>
      </div>
    </div>
    <!--用于显示查询结果的容器。-->
    <!-- <input type="text" id="YQNum" maxlength="50" /> -->
    <!--用于调用脚本方法的按钮。-->
    <!-- <input type="button" value="TRACK" onclick="doTrack()" /> -->
    <!--用于显示查询结果的容器。-->

    <template v-for="(item, index) in numList">
      <div :id="'YQContainer-' + index" :key="index"></div>
    </template>
  </div>
</template>

<script>
import { shippedInfo } from "../../utils/coupon";
export default {
  name: '',
  data() {
    return {
      logisticsId: 'RT122345656',
      numList: [],
      num: '',
      orderNo: '',
      timeLineList: [],
      flag: 0
    }
  },
  created() {
    this.numList = this.$route.query.deliverNumList.split('-')
    this.orderNo = this.$route.query.orderNo
    // console.log(this.orderNo, this.numList);
  },
  mounted() {
    this.numList.forEach((item, index) => {
      if (item.substring(0, 3) === 'FEI') {
        this.flag = 1
        this.doSteps()
      } else {
        this.doTrack(item, index)
      }
    })
  },
  methods: {
    doSteps() {
      this.$reqPost(shippedInfo.getOrderInfoList, {
        orderId: this.orderNo,
        orderNumbers: this.numList
      }).then((res) => {
        if (res.data.code === 200) {
          this.timeLineList = res.data.data.trackingInformations[0].trackingInfoDetails
        } else {
          this.$message.error(res.data.message)
        }
      })
    },
    doTrack(item, index) {
      // document.getElementById("YQContainer").innerHTML = numHtml

      YQV5.trackSingle({
        // 必须，指定承载内容的容器ID。
        YQ_ContainerId: 'YQContainer-' + index,
        // 可选，指定查询结果高度，最大为800px，默认为560px。
        YQ_Height: 560,
        // 可选，指定运输商，默认为自动识别。
        YQ_Fc: '0',
        // 可选，指定UI语言，默认根据浏览器自动识别。
        YQ_Lang: 'en',
        // 必须，指定要查询的单号。
        YQ_Num: item
      })
    }
  }
}
</script>
<style lang="less" scoped>
.all {
  padding: 18px;
}
/deep/.nq-button {
  float: right;
  padding: 7px 15px;
}
#YQContainer {
  margin-top: 10px;
}
.top {
  border: 1px solid #e0e0e0;
  margin-bottom: 10px;
}
.steps {
  display: flex;
  align-items: center;
  background: white;
  padding: 10px;
  border-radius: 5px;
  border-bottom: 1px solid #f1f0f0;
  .quan {
    width: 10px;
    height: 10px;
    background: #0cb1f3;
    border-radius: 50%;
    margin-right: 20px;
  }
  .neibu {
    width: calc(100% - 10px);
    p {
      padding: 5px 0;
      margin-top: 10px;
    }
  }
}
</style>
